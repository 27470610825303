<template>
  <Btn
    @click="$emit('click')"
    :class="{
      'bouton-son': !disabled,
      'bouton-mute': disabled,
    }"
  />
</template>

<style lang="scss" scoped>
.bouton-mute {
  cursor: pointer;
  user-select: none;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  .contour {
    display: none;
  }

  .inside {
    fill: #000;
  }

  &:hover .inside {
    fill: #222222;
  }

  &:active .inside {
    fill: #000;
  }

  $color: #ee3737;

  .background {
    fill: $color;
  }

  &:hover .background {
    fill: lighten($color, 3%);
  }

  &:active .background {
    fill: darken($color, 3%);
  }

  .croix {
    fill: #000;
  }

  &:hover .croix {
    fill: #222222;
  }

  &:active .croix {
    fill: #000;
  }

  .son {
    display: none;
  }
}

.bouton-son {
  cursor: pointer;
  user-select: none;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  .contour {
    display: none;
  }

  .inside {
    fill: #000;
  }

  &:hover .inside {
    fill: #222222;
  }

  &:active .inside {
    fill: #000;
  }

  $color: #47c684;

  .background {
    fill: $color;
  }

  &:hover .background {
    fill: lighten($color, 3%);
  }

  &:active .background {
    fill: darken($color, 5%);
  }

  .croix {
    display: none;
  }

  .son {
    fill: #000;
  }

  &:hover .son {
    fill: #222222;
  }

  &:active .son {
    fill: #000;
  }
}


</style>

<script>
import Btn from '@/resources/images/inline.bouton_mute.svg';

export default {
  name: 'BoutonMute',
  components: {
    Btn: Btn,
  },
  props: ['disabled'],
}
</script>
